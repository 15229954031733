body {
  font-family: 'Cabin', sans-serif;
}
body,
.btn,
.form-control {
  font-size: 14px;
}

.impFields{
  color:red
}

/* For Firefox */
input[type='number'] {
  -moz-appearance:textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.textUpperCase{
  text-transform: capitalize;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  margin: 0 auto;
  width: 100%;
  z-index: 9999;
  background-color: #ffffff78;
  top: 0;
}
.tab-content{
  display: none
}
.smartsheet_iframe iframe {
  width: 100%;
  height: 70vh;
}
.cm_alert_danger{
  color: red
}
.form-control.is-invalid{
  background-image:none !important
}
.gallery_item img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}


.auth_body_wrapper {
  background: #1ba0da;
  padding: 90px 0;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.auth_body_wrapper .container {
  background: #fff;
  border-radius: 4px;
  height: calc(100vh - 180px);
  min-height: 500px;
  box-shadow: 0px 10px 50px 0px rgba(0,0,0,0.3);
}

.login_background {
  background: #24a2db;
  position: relative;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.cm_login_form {
  width: 100%;
  padding: 0 15%;
}

.login_logo {
  margin-bottom: 10vh;
}

.create_acc_btn {
  margin-top: 20vh;
}

.btn {
  padding: 8px 30px;
}

.form-group {
  margin-bottom: 25px;
  position: relative;
}

.form-control.icon_form {
  height: calc(1.5em + .75rem + 10px);
  padding-left: 50px;
}

.auth_content {
  color: #fff;
  padding-left: 5%;
  padding-bottom: 3%;
}

.input_icon {
  position: absolute;
  width: 42px;
  height: 42px;
  border-right: 1px solid #ced4da;
  left: 0;
  top: 0;
}

span.input_icon svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
}

.forgot_pass {
  color: #818BA0;
}

.create_acc_btn_icon {
  display: inline-block;
  width: 7px;
  position: relative;
  left: 0px;
  animation-name: slide_right;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}









header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  border-bottom: 1px solid #E5E5E5;
  padding: 10px 75px;
  z-index: 999;
  background: #fff;
}
.footer_fix{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #292929;
  text-align: center;
  background: #fff;
  box-shadow: 0px 0px 4px #d2d2d2;
}

.copyright.footer_fix p {
  padding-top: 14px;
}
.logo {
  display: inline-block;
  width: 160px;
  vertical-align: middle;
}

.primary_menu {
  display: inline-block;
  width: calc(100% - 290px);
  vertical-align: middle;
}

.header_dropdown {
  display: inline-block;
  width: 130px;
  vertical-align: middle;
}

ul.profile_menu {
  margin: 0 -15px;
  padding: 0;
  list-style: none;
  margin-top: 25px;
}

.no_btn {
  background: transparent !important;
  border: none !important;
  outline: none;
  color: #000;
  padding: 0;
  font-size: 16px;
}

ul.profile_menu li a,
ul.profile_menu li span {
  padding: 10px;
  display: block;
  padding-left: 50px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  color: #818BA0;
}


ul.profile_menu li span {
  color: #1e8bff;
}

ul.profile_menu li a:hover,
ul.profile_menu li span:hover {
  background: #0D9CD8;
  color: #fff;
}

ul.profile_menu li svg {
  position: absolute;
  height: 18px;
  max-width: 16px;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  fill: #818BA0;
}

ul.profile_menu li span svg {
  fill: #0d9cd8;
}

ul.profile_menu li a:hover svg,
ul.profile_menu li span:hover svg {
  fill: #fff !important;
}
.no_btn:hover {
  color: #000;
  background-color: transparent;
  border: 0;
}

.no_btn:after {
  display: none;
}
.no_btn:focus,
.no_btn:not(:disabled):not(.disabled).active:focus, .no_btn:not(:disabled):not(.disabled):active:focus, .show>.no_btn.dropdown-toggle:focus {
  box-shadow: none;
}

.no_btn:not(:disabled):not(.disabled).active, .no_btn:not(:disabled):not(.disabled):active, .show>.no_btn.dropdown-toggle {
  color: #000;
}

span.menu_icon {
  display: inline-block;
  width: 15px;
}

span.down_angle {
  display: inline-block;
  width: 7px;
  transform: rotate(90deg);
  margin-left: 10px;
}

.header_dropdown_btn #dropdown-basic img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 16px;
}

.primary_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}

.primary_menu ul li {
  display: inline-block;
  margin-right: 50px;
}

.primary_menu ul li a {
  color: #000;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  position: relative; 
}

.primary_menu ul li a.active:after {
  content: "";
  width: 100%;
  height: 3px;
  background: #009df1;
  position: absolute;
  bottom: -27px;
  border-radius: 10px;
  left: 4px;
}
.min_height {
  min-height: 61px;
}

.header_dropdown .dropdown-menu {
  box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.16);
  transform: none !important;
  left: auto !important;
  right: 0;
  top: 47px !important;
  border: 0;
  border-radius: 0;
  width: 240px;
  padding: 20px 15px;
}

.header_profile {
  position: relative;
  padding-left: 70px;
}

span.header_pro_img img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
}

span.header_pro_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
}

span.upload_pro_img input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 99;
}

span.upload_pro_img {
  position: absolute;
  width: 100%;
  height: 24px;
  background: rgba(0,0,0,0.6);
  left: 0;
  bottom: 0;
}

span.upload_pro_img svg {
  position: absolute;
  width: 17px;
  left: 50%;
  transform: translateX(-50%);
  top: 3px;
}

.header_profile h4 {
  font-size: 22px;
  margin: 0;
}
.header_profile p {
  margin: 0;
}

.header_profile p a {
  color: #818BA0;
  text-decoration: none;
}
.profile_description {
  word-break: break-all;
}
.profile_project , .profile_site{
  text-align: center;
  margin-right: 25px;
}
.profile_project h3 , .profile_site h3{
  margin: 4px 0;
  color: #0D9CD8;
}
.logout-body {
  padding: 0rem 1rem 2rem !important;
}
.cm_box_view .col-form-label {
  color: #818BA0;
}
/* ----------------modal-------------------- */
.close {
  opacity: 1;
}
.modal-header .close span {
  padding: 0px;
    color: #000;
    opacity: 1;
    cursor: pointer;
    outline: none;
}
.modal-content {
  border-radius: 0;
  border: none;
}
.modal-header{
  border:none
}
.modal-body {
  padding: 0rem 3.5rem 2rem;
}
.custom_modal h6 {
  color: #818BA0;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 30px;
}
.custom_modal .modal-content {
  border-radius: 10px;
}
.suggest_site {
  min-height: 200px;
  max-height: 200px;
  display: flex;
}

  .suggest_site ul {
    list-style-type: none;
    padding-inline-start: 0px;
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;

}
.suggest_site ul li {
  color: #888;
  padding: 5px 2px;
}






.body_container {
  padding: 0 75px;
}

.cm_breadcumd {
  padding: 20px 0;
}

.cm_breadcumd p {
  margin: 0;
  color: #818BA0;
  font-size: 18px;
  font-weight: bold;
}

.img_height, .img_height img {
  height: 200px;
  object-fit: fill;
  width: 100%;
}

.project_card {
  border: 1px solid #E1E2E7;
  border-radius: 4px;
  margin-bottom: 40px;
  /* box-shadow: 8px 3px 12px 0px grey; */
  position: relative;
  cursor: pointer;
  min-height: 430px;
    max-height: 430px;
    overflow: hidden;
}
.project_card .discription {
  min-height: 42px;
  max-height: 42px;
  overflow: hidden;
}
.header_dropdown_btn.dropdown span {
  text-transform: capitalize;
}
.cm_card_padding {
  padding: 20px;
}
.cm_card_padding p{
  word-break: break-all;
  margin-bottom: 0;
}
.project_card h3 , .about_project_wrapper h3{
  color: #0D9CD8;
  font-size: 24px;
  font-weight: bold;
  text-transform: capitalize;
    margin-bottom: 4px;
}
.gallery_item {
  margin: 4px;
}
.project_card h5 , .about_project_wrapper h5{
  color: #818BA0;
  font-size: 14px;
  margin-bottom: 15px;
  text-transform: capitalize;
  
}

.project_card_img img {
  width: 100%;
  max-height: 250px;
  min-height: 250px;
  border-radius: 4px 4px 0 0;
    object-fit: cover;

}
.cm_box_view .form-group{
  margin-bottom: 12px;
}
.icon svg {
  width: 20px;
  height: 24px;
}
.readmore {
  text-align: right;
    margin: 12px
}
.primary_menu ul li a.active, .primary_menu ul li a.active span.menu_icon svg path {
  color: #0D9CD8;
  fill: #0D9CD8;
}

.readmore a {
  font-weight: bold;
  color: #0D9CD8;
}

.copyright {
  /* padding: 30px 75px; */
  text-align: center;
  padding-top: 0;
}

span.arrow_icon {
  display: inline-block;
  width: 14px;
  margin: 0 10px;
}

.cm_col_2 {
  flex: 0 0 140px;
  max-width: 140px;
}

.cm_col_4 {
  flex: 0 0 320px;
  max-width: 320px;
}

.cm_box_view .form-control, .cm_box_view .btn {
  border-radius: 0px;
}

.cm_box_view p {
  margin: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.profile_wrapper,
.change_password {
  min-height: calc(100vh - 180px);
}
.profile_wrapper {
  width: 600px;
  /* position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50% ,18%); */
}
.no_more_parent{
  position: relative;

  width: 100%;
  height: 60vh;
}
.no_more_child{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}
/* ---------------------profile---------------------- */
.header_profile_big {
  padding-left: 140px;
  min-height: 120px;
  padding-top: 30px;
  display: inline-block;
  max-width: 450px;
  width: 100%;
}
.flex-profile{
  display: flex;
  align-content: center;
  /* justify-content: ; */
}
span.header_pro_img_big {
  width: 120px;
  height: 120px;
}

span.header_pro_img_big img {
  width: 120px;
  height: 120px;
}

.header_profile_big h4 {
  font-size: 26px;
  margin: 0;
}

.profile_btn {
  display: inline-block;
}
.header_dropdown_btn .btn-primary {
  background-color: #0d9cd800 !important;
  border-color: #0d9cd800 !important;
  color: #000;
}
.cm_breadcumd .btn-primary , .btn-primary{
  background-color: #0d9cd8 !important;
    border-color: #0d9cd8 !important;
    border-radius: 5px;
    padding: 8px 22px;
}
.cm_breadcumd p span:first-child {
  text-decoration: underline;
  cursor: pointer;
}
.min-width120{
  min-width: 120px;
}
.profile_btn .btn {
  background: #0D9CD8;
  border-color: #0D9CD8;
  border-radius: 0;
  padding: 10px 40px;
  min-width: 146px;
}

.cm_tabs_wrapper .nav-tabs a {
  color: #818BA0;
  border: 0;
  border-radius: 0;
  padding: 14px 15px;
  display: inline-block;
  margin-right: 1px;
}

.cm_tabs_wrapper .nav-tabs a:hover,
.cm_tabs_wrapper .nav-tabs a.active {
  color: #fff;
  background-color: #0D9CD8;
  border: none;
}

.cm_tabs_wrapper .nav-tabs a svg {
  height: 18px;
  margin-right: 5px;
  display: inline-block;
}
.cm_tabs_wrapper .nav-tabs a svg path {
  fill: #818BA0;
}

.cm_tabs_wrapper .nav-tabs a:hover svg path,
.cm_tabs_wrapper .nav-tabs a.active svg path {
  fill: #fff;
}

.cm_tabs_wrapper .nav-tabs {
  border-bottom: 1px solid #E1E2E7;
  border-top: 1px solid #E1E2E7;
  padding-left: 75px;
}

.cm_tabs_wrapper {
  position: relative;
  padding-right: 350px;
}

.about_project_wrapper {
  position: absolute;
  right: 0;
  width: 350px;
  top: 0;
  border: 1px solid #E1E2E7;
  border-right: 0;
  min-height: 80vh;
}

.cm_breadcumd.cm_space {
  padding: 20px 75px;
}

.cm_tabs_wrapper .tab-content {
  min-height: calc(100vh - 229px);
}

.tabs_space_padding {
  /* padding: 20px;
  padding-left: 75px; */
  padding: 20px 75px;
}

.search_wrapper {
  position: relative;
  padding-right: 265px;
  min-height: 40px;
  margin-bottom: 20px;
}


.cm_search input {
  border-color: #E1E2E7;
  border-radius: 0;
  padding-left: 50px;
}

.cm_search {
  position: absolute;
  right: 0;
  top: 0;
  width: 250px;
}

.search_icon {
  position: absolute;
  width: 18px;
  top: 6px;
  left: 10px;
}

.min_breadcurm p {
  margin: 0;
}

.cm_folder_list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.folder_wrap {
  display: inline-block;
  text-align: center;
  margin-bottom: 30px;
  padding: 10px;
  cursor: pointer;
  min-width: 180px;
}

.folder_wrap:hover {
  background: #f8f8f8;
}

.folder_icon {
  display: inline-block;
  width: 75px;
}

.folder_wrap h3 {
  font-size: 18px;
  margin: 0;
  margin-top: 10px;
}
.disply_flex{
  display: flex;

  /* align-items: center;
  justify-content: space-between; */
}
.report_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
/* ------------------------------report------------------------------ */
.r_i_pdf {
  background: #F4F4F4;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 20px;
}
.report_item_half {
  display: flex;
  align-items: center;
}
.r_i_content h4 {
  color: #0d9cd8;
  font-size: 20px;
  text-transform: capitalize;
}
.cm_tabs_wrapper.site_tab {
  padding-right: 0px;
}
/* ------------------------------overview----------------------------- */
.overview h3 {
  font-size: 19px;
  margin-bottom: 0;
  padding: 6px 0px;
}
.overview p {
  font-size: 13px;
  color: #818BA0;
  padding: 12px 0;
  margin-bottom: 0;
}
.overview {
  word-break: break-all;
}
/* -------------------------------------sidebar icon--------------------- */
.icon_set {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.icon_set .icon {
  text-align: center;
}
.icon_set .icon svg {
  width: 20px;
  height: 20px;
}
.icon_set .icon p {
  color: #888;
  font-size: 12px;
  margin: 6px 0;
}
.description {
  padding: 15px 20px;
  font-size: 13px;
  word-spacing: 4px;
  letter-spacing: 0.5px;
  word-break: break-all;
}
.description p{
  margin-bottom: 0;
}
.interst_slider{
  margin: 20px 0;
padding:0 20px;
}
.interst_slider h4 {
  font-size: 17px;
}
.intersted_parent{
  display: flex;
  overflow: auto !important;
}
.intersted_visit {
  margin: 20px 6px;
}
/* -----------------------------------------medai inner------------------------ */
.media_parent {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.img_div {
  width: 56%;
    margin-right: 4%;
    position: relative;
    object-fit: cover;
    min-height: 450px;
    max-height: 450px;
}
.video-react {
  background-color: #0000;
}
video.video-react-video {
  object-fit: cover;
    min-height: 450px;
    max-height: 450px;
}
.img_div img {
  width: 100%;
  /* height: 550px;
  object-fit: contain; */
  object-fit: cover;
    min-height: 450px;
    max-height: 450px;
}
.carousel_btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
.left.carousel-control {
  float: left;
  cursor: pointer;
  margin-left: 20px;
}
.right.carousel-control {
  float: right;
  cursor: pointer;
  margin-right: 20px;
}
.desc_div {
  width: 40%;
}
.desc_div p {
  font-size: 30px;
  text-transform: capitalize;
}
.desc_div .icon_parent {
  display: flex;
}
.icon_parent .icon {
  text-align: center;
}
.icon_parent .icon i {
  border: 1px solid #747474;
  padding: 4px;
  border-radius: 4px;
  color: #747474;
}
.icon_parent .icon p {
  font-size: 13px;
  color: #292929;
  margin-top: 12px;
}
.icon_parent a {
  margin-right: 32px;
}
.desc_div .text {
  width: 100%;
  height: 426px;
  overflow-y: auto;
}
.desc_div .text p {
  font-size: 18px;
  white-space: normal;
  color: #616161;
  word-spacing: 4px;
}
.display_flx{
  display: flex;
}
@keyframes slide_right {
  to   {left: 20px;}
}


div#map {
  width: 100%;
  height: calc(100vh - 260px);
}

@media(min-width: 1620px){
  .xl_container {
      max-width: 1580px;
  }
}

@media(max-width: 991px){
  .auth_body_wrapper {
    padding: 60px 0;
    min-height: 100vh;
  }
  .profile_wrapper {
    width: 100%;
}
  .auth_content {
    padding: 30px 15px;
    padding-top: 100px;
  }

  .cm_login_form {
    width: 100%;
    padding: 0 15px;
  }

  .login_logo {
    margin-bottom: 60px;
    margin-top: 40px;
  }

  .create_acc_btn {
    margin-top: 50px;
  }
}

@media(max-width: 575px){
  .auth_body_wrapper {
    padding: 0 0 !important;
    min-height: 100vh;
  }
}



/**loaderrrrrrr**/
.loader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
}

.lds-roller {
  display: inline-block;
  width: 64px;
  position: absolute;
  height: 64px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes lds-roller {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}













